<template>
  <div class="card">
    <div class="card-body graph">
      <!-- Spline Area chart -->
      <div class="d-flex justify-content-between mb-4">
        <h4 class="card-title">Customer Acquisition Trend</h4>
        <b-button-group size="sm">
          <b-button :pressed="true" variant="primary" @click="getAllTrendbBy(7)"
            >7 days</b-button
          >
          <b-button
            :pressed="false"
            variant="primary"
            @click="getAllTrendbBy(15)"
            >15 days</b-button
          >
          <!-- <b-button :pressed="true" variant="primary" @click="getAllTrendbBy(29)">This Month</b-button> -->
          <b-button
            :pressed="false"
            variant="primary"
            @click="getMonthlyTrend()"
            >Month on Month</b-button
          >
        </b-button-group>
      </div>
      <VueApexCharts
        class="apex-charts"
        height="350"
        type="area"
        dir="ltr"
        :series="series"
        :options="chartOptions"
        :key="key"
      ></VueApexCharts>
    </div>
  </div>
</template>
<script>
import appConfig from "@/app.config";
// import {splineAreaChart} from "../charts/data-apex";
import VueApexCharts from "vue-apexcharts";
import filterMixins from "../../../mixins/filterData";
import moment from "moment";

export default {
  props: ["filters"],
  mixins: [filterMixins],
  components: {
    VueApexCharts,
  },
  data() {
    return {
      title: "Live Registrations",
      series: [
        {
          name: "Cummulative Registrations",
          data: [],
        },
        {
          name: "Current Registrations",
          data: [],
        },
      ],
      chart: {
        toolbar: {
          show: false,
        },
      },
      chartOptions: {
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          width: 3,
        },
        colors: ["#556ee6", "#34c38f"],
        xaxis: {
          type: "date",
          categories: [],
        },
      },
      key: 0,
      loading: true,
    };
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.getAllTrendbBy(7);
      },
    },
  },
  created() {
    // this.init();
  },
  methods: {
    init() {
      // this.loading = true;
      // this.loading = false;
    },
    disablenthChild() {
      document
        .querySelector(".card-body.graph .apexcharts-legend-text")
        .click();
    },
    getFormatedDate(date, type = "date") {
      if (type == "date") {
        return moment(date).format("DD MMMM");
      } else {
        return moment(date).format("MMMM");
      }
    },
    changeSeries(val = false) {
      if (val == false) {
        this.series = [
          {
            name: "Cummulative Registrations",
            data: [],
          },
          {
            name: "Current Registrations",
            data: [],
          },
        ];
        delete this.chartOptions.tooltip;
        this.chartOptions.colors = ["#556ee6", "#34c38f"];
      } else {
        this.series = [
          {
            name: "Current Registrations",
            data: [],
          },
        ];
        this.chartOptions.colors = ["#556ee6"];
        this.chartOptions.tooltip =  {
            custom: function({series, seriesIndex, dataPointIndex, w}) {
              var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
              let compute = Math.round(((data - series[0][dataPointIndex - 1]) / series[0][dataPointIndex - 1]) * 100) + '%';
              if (dataPointIndex == 0) {
                return '<div class="p-2"> <span>Current Registrations : </span>' + data + 
                      '<br>' +
                      '<span>MoM Growth : </span>' + 0 + '%' +
                      '</div>';
              }
              else {
                return  '<div class="p-2"> <span>Current Registrations : </span>' + data + 
                      '<br>' +
                      '<span>MoM Growth : </span>' + compute +
                      '</div>';
              }
            }
        }
      }
    },
    async getAllTrend() {
      this.changeSeries();
      const searchfiltersParams = this.getCleanFilteredData(this.filters);
      try {
        const response = await this.$http.get(
          appConfig.api_base_url + "/users/ndayTrend",
          {
            params: searchfiltersParams,
          }
        );
        this.series[1].data = response.data.n_days_trend.map(
          (n) => n.total_count
        );
        // this.series[1].data = [10,50,1900,20000]
        this.chartOptions.xaxis.categories = response.data.n_days_trend.map(
          (n) => this.getFormatedDate(n.date)
        );
        // this.chartOptions.xaxis.categories = ["2022-06-28","2022-06-29","2022-06-30","2022-06-31"];
        let total_trend = response.data.n_days_cumsum_trend[0].total_count;
        let cummulative_data = [];
        this.series[1].data.map((item, i) => {
          if (i == 0) {
            cummulative_data.push(parseInt(total_trend) + parseInt(item));
          } else {
            cummulative_data.push(
              parseInt(cummulative_data[i - 1]) + parseInt(item)
            );
          }
        });
        this.series[0].data = cummulative_data;
        this.key += 1;
        setTimeout(() => {
          this.disablenthChild();
        }, 100);
      } catch (err) {
        console.log(err);
      }
    },
    async getAllTrendbBy(val) {
      this.changeSeries();
      const searchfiltersParams = this.getCleanFilteredData(this.filters);
      try {
        const response = await this.$http.get(
          appConfig.api_base_url + "/users/ndayTrend?day_diff=" + val,
          {
            params: searchfiltersParams,
          }
        );
        this.series[1].data = response.data.n_days_trend.map(
          (n) => n.total_count
        );
        // this.series[1].data = [10,50,1900,20000]
        this.chartOptions.xaxis.categories = response.data.n_days_trend.map(
          (n) => this.getFormatedDate(n.date)
        );
        // this.chartOptions.xaxis.categories = ["2022-06-28","2022-06-29","2022-06-30","2022-06-31"];
        let total_trend = response.data.n_days_cumsum_trend[0].total_count;
        let cummulative_data = [];
        this.series[1].data.map((item, i) => {
          if (i == 0) {
            cummulative_data.push(parseInt(total_trend) + parseInt(item));
          } else {
            cummulative_data.push(
              parseInt(cummulative_data[i - 1]) + parseInt(item)
            );
          }
        });
        this.series[0].data = cummulative_data;
        this.key += 1;
        setTimeout(() => {
          this.disablenthChild();
        }, 100);
      } catch (err) {
        console.log(err);
      }
    },
    async getMonthlyTrend() {
      this.changeSeries(true);
      const searchfiltersParams = this.getCleanFilteredData(this.filters);
      try {
        const response = await this.$http.get(
          appConfig.api_base_url + "/users/monthlyTrend",
          {
            params: searchfiltersParams,
          }
        );
        this.series[0].data = response.data.month_on_month_trend.map(
          (n) => n.total_count
        );
        this.chartOptions.xaxis.categories =
          response.data.month_on_month_trend.map((n) =>
            this.getFormatedDate(n.month_year, "month")
          );
        this.key += 1;
        setTimeout(() => {
          this.disablenthChild();
        }, 100);
      } catch (err) {
        console.log(err);
      }
    },
  },
  mounted() {
    this.getAllTrendbBy(7);
  },
};
</script>
